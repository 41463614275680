// extracted by mini-css-extract-plugin
export var Advantage = "index-module--Advantage--MhGj3";
export var Banner = "index-module--Banner--bS6+f";
export var BannerButtonGroup = "index-module--BannerButtonGroup--1+Ioo";
export var BannerIcon = "index-module--BannerIcon--BgrWK";
export var DutyCardContent = "index-module--DutyCardContent--LZerr";
export var DutyCardGroup = "index-module--DutyCardGroup--cEOJ1";
export var Focus = "index-module--Focus--KF+Zp";
export var FrameImage = "index-module--FrameImage--xqtnE";
export var FrameworkContent = "index-module--FrameworkContent--14AIm";
export var MatterCardItem = "index-module--MatterCardItem--W8YPl";
export var SceneContent = "index-module--SceneContent--8TZhz";
export var SwitchPictureWrap = "index-module--SwitchPictureWrap--zx2ng";