import React from "react";
import classnames from "classnames";
import Seo from "components/seo";
import Layout from "layouts/zh";
import PageBottomCard from "components/PageBottomCard";
import { Languages } from "i18n/config";

import "styles/base.less";
import * as styles from "./index.module.less";

const Focus: React.FC = (props) => {
  const demoUrl = "";
  const paradeUrl = "";

  return (
    <Layout {...props}>
      <FocusState demoUrl={demoUrl} paradeUrl={paradeUrl} />
    </Layout>
  );
};

interface FocusStateProps {
  demoUrl?: string;
  paradeUrl?: string;
  lang?: Languages;
}
export const FocusState: React.FC<FocusStateProps> = (props) => {
  const {
    demoUrl,
    paradeUrl,
    lang
  } = props;

  return (
    <>
      <Seo
        title=""
        description=""
        keywords=""
        saTitle=""
      />
      <main className={classnames(styles.Focus)}>
        
        <PageBottomCard
          title=""
          desc=""
          leftButtonText=""
          leftButtonHref={demoUrl}
          rightButtonText=""
          rightButtonHref={paradeUrl}
        />
      </main>
    </>
  );
};

export default Focus;
